require('./files/libs/hinclude.js');
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

require('jquery-migrate');

const Translator = require('./files/bazingajstranslation/translator.min');
global.Translator = Translator;
require('../../web/js/translations/config');
require('../../web/js/translations/messages/de');
require('../../web/js/translations/messages/en');
require('./files/libs/jquery.block.js');
